import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { emailsValidator } from "src/app/shared/validators/multiple-emails.validator";

@Component({
  selector: "app-choose-emails-dialog",
  templateUrl: "./choose-emails-dialog.component.html",
  styleUrls: ["./choose-emails-dialog.component.scss"],
})
export class ChooseEmailsDialogComponent implements OnInit {
  form: FormGroup;
  subject;
  checkedAll = false;
  email = {
    cc: undefined,
    to: undefined,
    isExternal: undefined,
    type: undefined,
    _id: undefined,
    notes: undefined,
    file: undefined,
    currency: undefined,
    subject: undefined,
    payerResponse: undefined,
    invoices: undefined,
  };
  operations;
  errorMessage = "";
  error;
  constructor(
    public dialogRef: MatDialogRef<ChooseEmailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    let formVars: any = {
      cc: ["", [Validators.required, emailsValidator]],
    };

    this.form = this.formBuilder.group(formVars);

    if (this.data.data.subject.split("|").length > 1) {
      this.data.data.subject = this.data.data.subject
        .split("|")
        .slice(0, 2)
        .join(" | ");
    }

    this.subject = this.data.data.subject;

    if (this.data.data.cc || this.data.data.to) {
      this.form.get("cc").setValue(this.data.data.cc);
    }

    this.getOperataions();
    if (
      this.data.data.type === "OPERATION_START" ||
      this.data.data.type === "CONFORMIDAD_CAVALI"
    ) {
      if (this.data.data.to.split(",").length < 2) {
        this.error =
          "Se requieren por lo menos 2 contactos validados por Riesgos.";
      }
    }
  }

  onSubmit() {
    if (!this.error) {
      this.form.markAllAsTouched();
      this.form.updateValueAndValidity();

      if (this.data.operations.filter((inv) => inv.selected).length == 0) {
        this.errorMessage = "Debes de seleccionar al menos una operación";
      } else {
        if (this.form.valid) {
          this.email.cc = this.form.value.cc;
          this.email.to =
            this.data?.data.type === "OPERATION_START" ||
            this.data?.data.type === "CONFORMIDAD_CAVALI"
              ? this.data.data.to
              : this.form.value.to;
          this.email.type = this.data.data.type;
          this.email.isExternal = this.data.data.isExternal;
          this.email.currency = this.data.data.currency;
          this.email._id = this.data.data._id;
          this.email.subject = this.subject;
          this.email.payerResponse = this.form.value.payerResponse;
          this.email.invoices = this.data.operations.filter(
            (inv) => inv.selected
          );
          this.dialogRef.close(this.email);
        }
      }
    }
  }

  cancel() {
    this.dialogRef.close({ event: "Cancel" });
  }

  someComplete(): boolean {
    if (this.data?.operations == null) {
      return false;
    }
    return (
      this.data?.operations.filter((inv) => inv.selected).length > 0 &&
      !this.checkedAll
    );
  }

  selectAll() {
    this.checkedAll = !this.checkedAll;
    this.operations?.map((invoice) => {
      let obj = invoice.physicalInvoices.find(
        (element) =>
          element.cavaliStatus !== "in progress" && !element.cavaliPayerResponse
      );
      let obj2 = invoice.physicalInvoices.find(
        (element) =>
          element.cavaliStatus === "in progress" && element.cavaliPayerResponse
      );
      if (obj || (obj && obj2)) {
        invoice.selected = false;
        return invoice;
      } else {
        invoice.selected = this.checkedAll;
        return invoice;
      }
      //   invoice.physicalInvoices.some((element) => {
      //     if (
      //       element.cavaliStatus !== 'in progress' &&
      //       !element.cavaliPayerResponse
      //     ) {
      //       invoice.selected = false;
      //       return invoice;
      //     } else {
      //       invoice.selected = this.checkedAll;
      //       return invoice;
      //     }
      //   });
    });
    this.subject = `${this.data.data.subject} | ${this.data.operations
      .filter((inv) => inv.selected)
      .map((o) => o.physicalInvoices)
      .flat(1)
      .map((p) => p.code)
      .join(", ")}`;
  }

  changeCheck(op) {
    let invoicesObjs = this.operations,
      objIndex;
    objIndex = invoicesObjs.findIndex((ob) => ob._id == op.invoice._id);
    invoicesObjs[objIndex].selected = !invoicesObjs[objIndex].selected;
    this.operations = invoicesObjs;

    this.checkIfAllselected();
  }

  changeOperation(status, op) {
    // console.log(status, op);
  }

  checkIfAllselected() {
    if (
      this.operations.filter((inv) => inv.selected).length ===
      this.operations.length
    ) {
      this.checkedAll = true;
    } else {
      this.checkedAll = false;
    }

    this.subject = `${this.data.data.subject} | ${this.data.operations
      .filter((inv) => inv.selected)
      .map((o) => o.physicalInvoices)
      .flat(1)
      .map((p) => p.code)
      .join(", ")}`;
  }

  getOperataions() {
    if (this.data?.operations && !this.data.data.invoices) {
      this.data?.operations.map((e, i) => {
        let sent = e?.emailLog.find(
          (ope) =>
            ope.type === "OPERATION_START" || ope.type === "CONFORMIDAD_CAVALI"
        );
        let obj = e.physicalInvoices.find(
          (element) =>
            element.cavaliStatus !== "in progress" &&
            !element.cavaliPayerResponse
        );
        let obj2 = e.physicalInvoices.find(
          (element) =>
            element.cavaliStatus === "in progress" &&
            element.cavaliPayerResponse
        );

        let obj3 = e.physicalInvoices.find(
          (element) =>
            element.cavaliStatus === "in progress" &&
            !element.cavaliPayerResponse
        );

        if (obj || (obj && obj2)) {
          e.selected = false;
          return e;
        } else if (obj3 && !sent) {
          e.selected = true;
          return e;
        } else if (sent) {
          e.selected = false;
        } else {
          e.selected = true;
          return e;
        }
        // e.physicalInvoices.some((element) => {
        //   if (
        //     element.cavaliStatus !== 'in progress' &&
        //     !element.cavaliPayerResponse
        //   ) {
        //     e.selected = false;
        //     return e;
        //   } else {
        //     e.selected = true;
        //     return e;
        //   }
        // });
      });
      this.operations = this.data?.operations;
    } else {
      let invoicesObjs = this.data.operations;
      let objIndex;
      let invoiceIDs = this.data.data.invoices;
      invoiceIDs.map((op) => {
        objIndex = invoicesObjs.findIndex((ob) => ob._id == op._id);
        invoicesObjs[objIndex].physicalInvoices = op.physicalInvoices;
        invoicesObjs[objIndex].selected = op.selected;
      });

      this.operations = invoicesObjs;
    }
    this.subject = `${this.data.data.subject} | ${this.data.operations
      .filter((inv) => inv.selected)
      .map((o) => o.physicalInvoices)
      .flat(1)
      .map((p) => p.code)
      .join(", ")}`;
  }
}

<div class="container-cards">
  <div class="row row__1">
    <app-card-user-risk-information>
      <div class="top">
        <div *ngIf="role !== 'account manager'" class="readonly">
          <p class="tiny tiny-title line-title">Girador</p>
          <div
            *ngIf="
              evaluation.supplier?.companyName || evaluation?.userCompanyName
            "
            class="tooltip-container-not-dots"
          >
            <span
              *ngIf="
                evaluation?.supplier?.companyName || evaluation?.userCompanyName
              "
              class="value t-bold break line"
              >{{
                evaluation.supplier?.companyName
                  ? (evaluation.supplier?.companyName
                    | limitTo : "20"
                    | uppercase)
                  : evaluation?.userCompanyName
                  ? (evaluation?.userCompanyName | limitTo : "20" | uppercase)
                  : "---"
              }}</span
            >
            <span class="tooltiptext-bottom line">{{
              evaluation.supplier?.companyName
                ? (evaluation.supplier?.companyName | uppercase)
                : evaluation?.userCompanyName
                ? (evaluation?.userCompanyName | uppercase)
                : "---"
            }}</span>
          </div>
          <div
            class="list-values"
            *ngIf="
              evaluation?.suppliers &&
              evaluation?.suppliers !== '' &&
              stringToList(evaluation.suppliers).length <= 2
            "
          >
            <span
              class="value t-bold line break"
              *ngFor="let supplier of stringToList(evaluation.suppliers)"
              >{{ supplier }}</span
            >
          </div>
          <div
            class="list-values tooltip-container"
            *ngIf="
              evaluation?.suppliers &&
              evaluation?.suppliers !== '' &&
              stringToList(evaluation.suppliers).length > 2
            "
          >
            <span
              class="value t-bold break line"
              *ngFor="let supplier of stringToList(evaluation.suppliers)"
              >{{ supplier }}</span
            >
            <span class="tooltiptext">{{ evaluation?.suppliers }}</span>
          </div>
          <span
            class="value t-bold line"
            *ngIf="
              (!evaluation?.suppliers || evaluation?.suppliers === '') &&
              !evaluation?.supplier?.companyName &&
              !(evaluation.supplier?.companyName || evaluation?.userCompanyName)
            "
            >---</span
          >
        </div>
        <div *ngIf="role === 'account manager'" class="readonly"></div>
        <p
        *ngIf="evaluation?.supplierDocuments"
          (click)="downloadSupplierDocuments()"
          class="donwload-docs-button"
        >
          Descargar documentos
        </p>
      </div>
      <!-- <div class="bottom">
        <div class="readonly">
          <p class="tiny tiny-title">Actividad</p>
          <div
            *ngIf="evaluation.supplierActivity"
            class="tooltip-container-not-dots"
          >
            <span class="value">{{
              evaluation.supplierActivity | limitTo : "20" | uppercase
            }}</span>
            <span class="tooltiptext-bottom">{{
              evaluation.supplierActivity | uppercase
            }}</span>
          </div>

          <div *ngIf="!evaluation.supplierActivity">
            <span class="value">---</span>
          </div>
        </div>
        <div *ngIf="evaluation?.invoiceNetAmount" class="readonly right">
          <p class="tiny tiny-title">Monto de facturas</p>
          <div *ngIf="evaluation.invoiceNetAmount">
            <span class="value">{{
              evaluation.invoiceNetAmount | numberSpacing
            }}</span>
          </div>

          <div *ngIf="!evaluation.invoiceNetAmount">
            <span class="value">---</span>
          </div>
        </div>
      </div> -->
    </app-card-user-risk-information>
  </div>

  <div class="row row__2">
    <app-card-user-risk-information>
      <div class="top">
        <div class="readonly code">
          <p class="tiny tiny-title line-title">Pagador</p>
          <ng-container>
            <div class="copy tooltip-container-not-dots" (click)="copyCCI()">
              <span class="value t-bold value__companyName line">{{
                evaluation.debtor.companyName
                  ? (evaluation.debtor.companyName | limitTo : "30" | uppercase)
                  : "---"
              }}</span>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="16"
                viewBox="0 0 22 25"
              >
                <g>
                  <g>
                    <path
                      fill="#6c727c"
                      d="M19.163 6.818H6.767v15.91h12.396zm2.266 0v15.91c0 1.25-1.015 2.272-2.256 2.272H6.767c-1.24 0-2.256-1.023-2.256-2.273V6.818c0-1.25 1.015-2.273 2.256-2.273h12.406c1.24 0 2.256 1.023 2.256 2.273zm-5.64-4.545H2.256v15.909H0V2.272C0 1.023 1.015 0 2.256 0h13.533z"
                    />
                  </g>
                </g>
              </svg>
              <span *ngIf="cciCopied | async" class="copied-message"
                >¡Copiado al portapapeles!</span
              >
              <span class="tooltiptext-bottom"
                >RUC:
                {{
                  evaluation.debtor.companyRuc
                    ? evaluation.debtor.companyRuc
                    : "---"
                }}</span
              >
            </div>
            <!--span *ngIf="cciCopied | async" class="copied-message">¡Copiado!</span-->
          </ng-container>
        </div>
        <div class="readonly right">
          <p class="tiny tiny-title line-title">Linea propuesta</p>
          <span class="value tiny-title line">{{
            evaluation.debtorLineProposalV4
              ? "S/ " + (evaluation.debtorLineProposalV4 | numberSpacing)
              : "---"
          }}</span>
        </div>
        <!-- <div class="actions">
          <div class="tooltip-container-not-dots">
            <app-rounded-button-action
              *ngIf="evaluation.debtorActivity"
              [background]="'dark-gray'"
              [imgIcon]="'notes.svg'"
            ></app-rounded-button-action>
            <span class="tooltiptext-bottom">{{
              evaluation.debtorActivity | capitalizeOnlyFirstLetter
            }}</span>
          </div>
          <div class="tooltip-container-not-dots">
            <app-rounded-button-action
              *ngIf="evaluation.debtorComment"
              [background]="'dark-gray'"
              [imgIcon]="'comments.svg'"
            ></app-rounded-button-action>
            <span class="tooltiptext-bottom">{{
              evaluation.debtorComment | capitalizeOnlyFirstLetter
            }}</span>
          </div>
          <div
            *ngIf="
              role == 'risk analyst' ||
              role == 'account manager supervisor' ||
              role == 'account manager'
            "
            (click)="openDocumentDialog(evaluation)"
            class="tooltip-container-not-dots"
          >
            <app-rounded-button-action
              [background]="'blue'"
              [imgIcon]="'attach-file.svg'"
            >
            </app-rounded-button-action>
            <span class="tooltiptext-bottom">Documentos</span>
          </div>
        </div> -->
      </div>
      <div class="bottom">
        <p
          (click)="openInformationModal()"
          class="information-text information-text__{{ modalState() }} line"
        >
          Ver más información
        </p>
        <!-- <div class="readonly">
          <p class="tiny tiny-title">Detalle de la factura</p>
          <div
            *ngIf="evaluation.invoiceDetail"
            class="tooltip-container-not-dots"
          >
            <span class="value">{{
              evaluation.invoiceDetail | limitTo : "20" | uppercase
            }}</span>
            <span class="tooltiptext-bottom">{{
              evaluation.invoiceDetail | uppercase
            }}</span>
          </div>

          <div *ngIf="!evaluation.invoiceDetail">
            <span class="value">---</span>
          </div>
        </div> -->
      </div>
    </app-card-user-risk-information>
  </div>
</div>
